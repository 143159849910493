import Vue from 'vue'
import VueRouter from 'vue-router'

import NotFoundPage from '@/views/NotFoundPage.vue'
import AboutUsPage from '@/views/AboutUsPage.vue'
import ServicesPage from '@/views/ServicesPage.vue'
import NIEConnectionConfigurationPage from '@/views/NIEConnectionConfigurationPage.vue'
import PassThroughChargesPage from '@/views/PassThroughChargesPage.vue'
import NIEApplicationsPage from '@/views/NIEApplicationsPage.vue'
import EnergyTradingPage from '@/views/EnergyTradingPage.vue'
import EnergyPriceAlertsPage from '@/views/EnergyPriceAlertsPage.vue'
import MICBrokeragePage from '@/views/MICBrokeragePage.vue'
import EVChargingPage from '@/views/EVChargingPage.vue'
import ContactUsPage from '@/views/ContactUsPage.vue'

Vue.use(VueRouter)

const routes = [
  { path: '*', component: NotFoundPage },
  { path: '/', component: AboutUsPage },
  { path: '/services', component: ServicesPage },
  { path: '/services/energy-trading', component: EnergyTradingPage },
  { path: '/services/nie-applications', component: NIEApplicationsPage },
  { path: '/services/nie-connection-configuration', component: NIEConnectionConfigurationPage },
  { path: '/services/nie-connection-configuration/pass-through-charges', component: PassThroughChargesPage },
  { path: '/services/energy-price-alerts', component: EnergyPriceAlertsPage },
  { path: '/services/mic-brokerage', component: MICBrokeragePage },
  { path: '/services/ev-charging', component: EVChargingPage },
  { path: '/contact-us', component: ContactUsPage }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
